/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { SpinnerRoundFilled } from 'spinners-react';
import { useDispatch } from 'react-redux';
import { TableContext } from '../../contexts/tableContext';
import styles from './ActivateSubscription.module.scss';
import SelectBox from '../SelectBox/SelectBox';
import { Entry } from '../../redux/entrySlice';

const ActivateSubscription = React.memo(({ dataId, handleClose }) => {
  const dispatch = useDispatch();
  const { setDeletedData } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const [loading, setLoading] = useState(false);

  const periods = [
    { label: 'One Month', value: '1' },
    { label: 'A Quarter', value: '3' },
    { label: 'Six Months', value: '6' },
    { label: 'A Year', value: '12' },
  ];

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const addSubscription = async (data) => {
    setLoading(true);
    const apiData = {
      period: data.period.value,
    };
    data.actionUrl = `trial/${dataId}`;
    data.actionMethod = 'post';
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setLoading(false);
      if (resp.payload.code === 200) {
        toast.success('Subscription added successfully.');
        reset();
        setDeletedData(resp.payload.data.updatedUser.id);
        handleClose();
      }
    });
  };
  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
          onKeyPress={handleClose}
        >
          x
        </span>
        {loading ? (
          <SpinnerRoundFilled style={{ marginLeft: '50%' }} />
        ) : (
          <div>
            <form onSubmit={handleSubmit(addSubscription)} className={styles.detaildiv}>
              <SelectBox
                className={styles.inputbox}
                classNamedark={styles.inputbox1}
                name="period"
                label="Period"
                labelstyle={styles.formlabel}
                control={control}
                register={register}
                values={periods}
                errors={errors}
                placeholder="Choose Period"
                mandatory
                isRequired
              />
              <button type="submit" className={styles.subbtn}>
                {loading ? 'Please wait...' : 'Submit'}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
});

ActivateSubscription.propTypes = {
  dataId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ActivateSubscription;
